<template>
  <form
    class="account-change-password card border-0 shadow"
    @submit.prevent="submit"
  >
    <div class="card-body">
      <h3 class="font-weight-bold mb-4">Alterar senha</h3>

      <form-group
        id="password_old"
        v-model="form.password_old"
        :errors="errors.password_old"
        type="password"
        placeholder="Senha atual"
        label="Senha atual"
      />

      <form-group
        id="password"
        v-model="form.password"
        :errors="errors.password"
        type="password"
        placeholder="Nova senha"
        label="Nova senha"
      />

      <div class="text-center pt-3">
        <button
          type="submit"
          class="btn btn-success px-5 font-weight-bold"
          :disabled="loading"
        >
          <loading :show="loading">Salvar</loading>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { formFields } from "@/functions/helpers.js";

export default {
  data() {
    return {
      loading: false,
      ...formFields(["password_old", "password"])
    };
  },
  methods: {
    submit() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("user/updatePassword", this.form)
        .then(() => {
          this.$message.success("Senha alterada com sucesso!");
        })
        .catch(error => {
          this.errors = error.response.data;
          if (this.errors.non_field_errors) {
            this.$message.error(this.errors.non_field_errors[0]);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
